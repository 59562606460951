/*
  *App
  bill_yang 2021.06
*/
import 'antd/dist/antd.css';
import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Layout from './components/layout/Layout'; //主页布局
import { layoutLessRoute, mainRoutes, tokenRouter, userRouter } from './routes'; // 路由

// import Loading from './components/public/Loading';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { connect } from 'react-redux';
import Personal from './components/layout/Personal';
import Loading from './pages/state/Loading';

// Create a client
const queryClient = new QueryClient();
// 路由配置

const AppWithRouter = (props) => {
  return (
    <Switch>
      {layoutLessRoute.map((route) => {
        return (
          <Route
            path={route.path}
            key={route.path}
            exact
            render={(routeProps) => {
              return <route.component {...routeProps} />;
            }}
          />
        );
      })}
      {/* 主路由 */}
      <Layout>
        <Switch>
          {mainRoutes.map((route) => {
            return (
              <Route
                path={route.path}
                key={route.path}
                exact
                render={(routeProps) => {
                  return <route.component {...routeProps} />;
                }}
              />
            );
          })}
          {!props.getToken ? (
            <Redirect to="/" />
          ) : (
            tokenRouter.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact
                  render={(routeProps) => {
                    return <route.component {...routeProps} />;
                  }}
                />
              );
            })
          )}
          <Personal>
            {!props.getToken ? (
              <Redirect to="/" />
            ) : (
              userRouter.map((route, index) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    exact
                    render={(routeProps) => {
                      return <route.component {...routeProps} />;
                    }}
                  />
                );
              })
            )}
          </Personal>
          <Redirect to="/404" />
        </Switch>
      </Layout>
    </Switch>
  );
};

class App extends React.Component {
  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <BrowserRouter>
          <React.Suspense fallback={<Loading />}>
            <AppWithRouter getToken={this.props.getToken} />
          </React.Suspense>
        </BrowserRouter>
      </QueryClientProvider>
    );
  }
}
// react-redux状态
const mapStateToProps = (state) => {
  return {
    getToken: state.getToken,
  };
};
export default connect(mapStateToProps)(App);
