import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import metaData from './data.json';

const HeaderMeta = ({ type, children }) => {
  const seoMetaData = metaData[type] || metaData['default'];

  return (
    <Helmet>
      <title>{seoMetaData.title}</title>
      <meta name="description" content={seoMetaData.description} />
      <meta name="keywords" content={seoMetaData.keywords.join(',')} />
      {children}
    </Helmet>
  );
};

HeaderMeta.propTypes = {
  type: PropTypes.oneOf(Object.keys(metaData)).isRequired,
  children: PropTypes.node,
};

export default HeaderMeta;
